define("discourse/plugins/discourse-cartalk/discourse/components/cartalk-make-model", ["exports", "@ember/component", "@ember/object/computed", "jquery", "discourse/lib/url", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _jquery, _url, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    title: "Filter by vehicle make and model",
    classNameBindings: [":cartalk-mm-nav-item", "showPopup:active"],
    layoutName: "templates/components/cartalk-make-model",
    readyToShow: false,
    showPopup: false,
    makesGroup: null,
    modelsGroups: null,
    makeSelected: null,
    modelSelected: null,
    modelsEmpty: (0, _computed.empty)("modelsForSelect"),
    makesForSelect(makesGroup) {
      return makesGroup.get("tag_names").map(tagName => {
        return {
          id: tagName,
          name: tagName
        };
      });
    },
    modelsForSelect(makeSelected) {
      if (makeSelected && this.modelsGroups[makeSelected]) {
        return this.modelsGroups[makeSelected].tag_names.map(tagName => {
          return {
            id: tagName,
            name: tagName
          };
        });
      } else {
        return [];
      }
    },
    _filterByMake() {
      if (this.makeSelected && this.modelsEmpty) {
        // Some makes have no models, like Hummer. Show filtered results immediately.
        _url.default.routeTo(`/tag/${this.makeSelected}`);
      }
    },
    _filterByModel() {
      if (this.modelSelected) {
        _url.default.routeTo(`/tag/${this.modelSelected}`);
      }
    },
    init() {
      this._super(...arguments);
      this.set("modelsGroups", {});
      this.set("readyToShow", false);
      this.store.findAll("cartalk/makeModelTagGroup").then(groups => {
        this.set("makesGroup", groups.find(item => item.name === "Makes"));
        groups.forEach(item => {
          if (item.name.indexOf("Makes-") === 0) {
            this.modelsGroups[item.name.substring(6).toLowerCase()] = item;
          }
        });
        this.set("readyToShow", true);
      });
    },
    didInsertElement() {
      this._super(...arguments);
      const $html = (0, _jquery.default)("html");
      $html.on("mousedown.outside-cartalk-mm", e => {
        if ((0, _jquery.default)(this.element).has(e.target).length !== 0) {
          return;
        }
        this.set("showPopup", false);
        return true;
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      this.set("showPopup", false);
      (0, _jquery.default)("html").off("mousedown.outside-cartalk-mm");
    },
    actions: {
      togglePopup() {
        if (this.readyToShow) {
          this.toggleProperty("showPopup");
        }
      }
    }
  }, [["method", "makesForSelect", [(0, _decorators.default)("makesGroup")]], ["method", "modelsForSelect", [(0, _decorators.default)("makeSelected")]], ["method", "_filterByMake", [(0, _decorators.observes)("modelsForSelect")]], ["method", "_filterByModel", [(0, _decorators.observes)("modelSelected")]]]));
});