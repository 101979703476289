define("discourse/plugins/discourse-cartalk/discourse/controllers/admin-plugins-cartalk", ["exports", "@ember/controller", "@ember/object/computed", "discourse/lib/ajax"], function (_exports, _controller, _computed, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    showHtml: true,
    selected: "digests",
    showingDigests: (0, _computed.equal)("selected", "digests"),
    showingHouseAds1: (0, _computed.equal)("selected", "house_ads_1"),
    showingHouseAds2: (0, _computed.equal)("selected", "house_ads_2"),
    showingHouseAds: (0, _computed.or)("showingHouseAds1", "showingHouseAds2"),
    loadingAds: true,
    adHtml(set, num) {
      return (this[`model.house_ads_${set}`][num] || {
        html: ""
      }).html;
    },
    actions: {
      showDigests() {
        this.set("selected", "digests");
      },
      showHouseAds1() {
        this.set("selected", "house_ads_1");
        for (let i = 0; i < 5; i++) {
          this.set(`adUnit${i}`, this.adHtml(1, i));
        }
      },
      showHouseAds2() {
        this.set("selected", "house_ads_2");
        for (let i = 0; i < 5; i++) {
          this.set(`adUnit${i}`, this.adHtml(2, i));
        }
      },
      refreshDigest() {
        this.set("loading", true);
        (0, _ajax.ajax)("/admin/plugins/cartalk/preview_make_model", {
          type: "GET",
          data: {
            username: this.username,
            last_seen_at: this.lastSeen
          }
        }).then(email => {
          this.setProperties({
            html_content: email.html_content,
            text_content: email.text_content
          });
        }).finally(() => this.set("loading", false));
      },
      toggleShowHtml() {
        this.toggleProperty("showHtml");
      },
      saveHouseAd(id) {
        (0, _ajax.ajax)(`/admin/plugins/cartalk/house_ads/${id}`, {
          type: "PUT",
          data: {
            html: this.get(`adUnit${id}`),
            set: this.showingHouseAds1 ? "1" : "2"
          }
        });
      },
      updateUsername(selected) {
        this.set("username", selected.firstObject);
      }
    }
  });
});