define("discourse/plugins/discourse-cartalk/discourse/templates/connectors/discovery-list-container-top/discourse-cartalk", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if site.mobileView}}
    {{cartalk-make-model}}
  {{/if}}
  */
  {
    "id": "/HebWBM0",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"  \"],[41,[28,[32,0],[\"cartalk-make-model\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"cartalk-make-model\"],[[28,[32,1],[\"cartalk-make-model\"],null]]]]],[[[1,[52,[30,1,[\"cartalk-make-model\"]],[28,[30,1,[\"cartalk-make-model\"]],null,null],[28,[32,2],[[30,0],\"cartalk-make-model\",\"[\\\"The `cartalk-make-model` property path was used in the `discourse/plugins/discourse-cartalk/discourse/templates/connectors/discovery-list-container-top/discourse-cartalk.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.cartalk-make-model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"]],[]],null],[1,[28,[32,3],[\"[[\\\"The `site` property path was used in the `discourse/plugins/discourse-cartalk/discourse/templates/connectors/discovery-list-container-top/discourse-cartalk.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.site}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"cartalk-make-model\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/connectors/discovery-list-container-top/discourse-cartalk.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});