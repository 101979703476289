define("discourse/plugins/discourse-cartalk/discourse/initializers/apply-cartalk", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse/models/post"], function (_exports, _object, _pluginApi, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "apply-cartalk",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      _post.default.reopen({
        showCarTalkHouseAds1: (0, _object.computed)("post_number", function () {
          const n = siteSettings.cartalk_ads_1_after_nth_post;
          return n && n === this.post_number; // only show once per topic as per their spec
        }),
        showCarTalkHouseAds2: (0, _object.computed)("post_number", function () {
          const n = siteSettings.cartalk_ads_2_after_nth_post;
          return n && n === this.post_number; // only show once per topic as per their spec
        })
      });
      (0, _pluginApi.withPluginApi)("0.1", api => {
        api.decorateWidget("post:after", dec => {
          if (dec.canConnectComponent) {
            return dec.connect({
              component: "discourse-cartalk",
              context: "model"
            });
          }
          return dec.connect({
            templateName: "connectors/post-bottom/discourse-cartalk",
            context: "model"
          });
        });
      });
    }
  };
});