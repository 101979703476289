define("discourse/plugins/discourse-cartalk/discourse/routes/admin-plugins-cartalk", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    controllerName: "admin-plugins-cartalk",
    model() {
      return (0, _ajax.ajax)("/admin/plugins/cartalk/house_ads.json");
    },
    setupController(controller, model) {
      controller.setProperties({
        model,
        loadingAds: false
      });
    }
  });
});