define("discourse/plugins/discourse-cartalk/discourse/templates/connectors/post-bottom/discourse-cartalk", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{discourse-cartalk model=this}}
  */
  {
    "id": "5oEdjTFN",
    "block": "[[[1,[28,[35,0],null,[[\"model\"],[[30,0]]]]]],[],false,[\"discourse-cartalk\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/connectors/post-bottom/discourse-cartalk.hbs",
    "isStrictMode": false
  });
});