define("discourse/plugins/discourse-cartalk/discourse/components/composer-make-model", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    title: "Tag topic with vehicle make and model",
    classNameBindings: [":cartalk-composer-make-model"],
    layoutName: "templates/components/composer-make-model",
    makesGroup: null,
    modelsGroups: null,
    makeSelected: null,
    modelSelected: null,
    prevMakeSelected: null,
    prevModelSelected: null,
    modelsDisabled: (0, _computed.empty)("modelsForSelect"),
    init() {
      this._super(...arguments);
      this.set("modelsGroups", {});
    },
    makesForSelect(makesGroup) {
      if (makesGroup && makesGroup.get("tag_names")) {
        return makesGroup.get("tag_names").map(tagName => {
          return {
            id: tagName,
            name: tagName
          };
        });
      } else {
        return [];
      }
    },
    modelsForSelect(makeSelected) {
      if (makeSelected && this.modelsGroups[makeSelected]) {
        return this.modelsGroups[makeSelected].tag_names.map(tagName => {
          return {
            id: tagName,
            name: tagName
          };
        });
      } else {
        return [];
      }
    },
    _addTagsToTopic() {
      if (this.composer) {
        // keep other tags that were added, replacing make and model tags
        let topicTags = this.composer.tags;
        if (topicTags === undefined) {
          topicTags = [];
        }
        topicTags = topicTags.reject(t => {
          return t === this.prevMakeSelected || t === this.prevModelSelected;
        });
        topicTags.pushObject(this.makeSelected);
        topicTags.pushObject(this.modelSelected);
        this.composer.set("tags", topicTags.reject(t => !t || t.length === 0).uniq());
      }
      this.setProperties({
        prevMakeSelected: this.makeSelected,
        prevModelSelected: this.modelSelected
      });
    },
    setupComboBoxes() {
      this.store.findAll("cartalk/makeModelTagGroup").then(groups => {
        this.set("makesGroup", groups.find(item => item.name === "Makes"));
        groups.forEach(item => {
          if (item.name.indexOf("Makes-") === 0) {
            this.modelsGroups[item.name.substring(6).toLowerCase()] = item;
          }
        });
      });
    }
  }, [["method", "makesForSelect", [(0, _decorators.default)("makesGroup")]], ["method", "modelsForSelect", [(0, _decorators.default)("makeSelected")]], ["method", "_addTagsToTopic", [(0, _decorators.observes)("modelSelected", "makeSelected")]], ["method", "setupComboBoxes", [(0, _decorators.on)("init")]]]));
});